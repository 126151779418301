.confirmation-modal .ant-modal-footer {
  display: flex;
  justify-content: center;
}

.confirmation-modal .ant-modal-footer button {
  width: 80px
}

.confirmation-modal .ant-modal-title,
.confirmation-modal .ant-modal-body {
  text-align: center;
}

.confirmation-modal .ant-modal-content {
  border-radius: 24px;
}

.confirmation-modal .ant-modal-header {
  border-top-left-radius: 24px;
  border-top-right-radius: 24px;
}