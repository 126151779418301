@media screen and (min-width: 1441px) {
   .sign-out-btn {
      margin-top: 16px;
   }
}

@media screen and (max-width: 1440px) {
   .sign-out-btn {
      margin-top: 4px;
   }
   .table-layout-container {
      overflow-x: auto !important;
   }
}

@media screen and (max-width: 768px) {
   .search-form {
      justify-content: center;
   }

   .select-options-container,
   .user-options-container {
      text-align: center;
      display: block;
   }

   .search-form .ant-form-item-label {
      display: none;
   }

   .mod-media-select-actions {
      margin-right: 0 !important;
   }

   .mod-media-bulk-actions-div,
   .user-options-container .mod-media-select-actions {
      margin-top: 10px;
   }

   .wall-menu-container .ant-menu {
      justify-content: center !important;
   }

   .date-filter-container {
      justify-content: center;
      margin-bottom: 10px !important;
   }

   .date-filter-container .ant-form-item {
      margin-right: 16px !important;
   }

   .date-filter-container .ant-form-item .ant-row {
      display: block !important;
   }

   .date-filter-container .ant-form-item-label {
      flex: 0 0 25% !important;
   }

   .date-filter-container .ant-form-item-control {
      flex: 0 0 75% !important;
   }
}

@media screen and (max-width: 480px) {
   .user-stats-wrapper .ant-space-item {
      width: 31.33%;
   }

   .user-stats-wrapper .ant-space-item .ant-card {
      width: 100% !important;
   }

   .pagination-container {
      display: block !important;
   }

   .pagination-container form {
      justify-content: center;
   }

   .pagination-container form .ant-form-item:nth-child(2) {
      margin-right: 0 !important;
   }

   .pagination-container .pagination {
      padding-left: 0 !important;
   }

   .newsletter-form-container {
      padding: 0 15px;
   }

   .table-layout-container thead {
      background: rgba(255, 20, 147, 0.1);
   }
}
