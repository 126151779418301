body {
   margin: 0;
   font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
      'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale;
}

code {
   font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.fullwidth {
   width: 100% !important;
}

.ant-image-preview-body {
   background-color: #000000b8;
}

.ant-image-preview-operations {
   background-color: hotpink !important;
   height: 65px;
}

.ant-image-preview-operations-icon {
   font-size: 25px !important;
}

/* pagination styles - start */
.pagination {
   display: flex;
   justify-content: center;
   list-style: none;
   cursor: pointer;
   margin-top: 10px;
}

.pagination a {
   padding: 10px;
   border-radius: 5px;
   border: 1px solid #1890ff;
   color: #1890ff;
}

.pagination li {
   margin: 7px;
}

.pagination__link {
   font-weight: bold;
}

.pagination__link--active a {
   color: #fff;
   background: #1890ff;
}

.pagination__link--disabled a {
   color: rgb(198, 197, 202);
   border: 1px solid rgb(198, 197, 202);
}

/* pagination styles end */

/* thumbnail styles */
/* .react-thumbnail-generator {
   height: 10em;
} */

/* .react-thumbnail-generator img {
   margin-left: -16px;
   margin-top: -19px;
} */
.menu-flex-end {
   flex: 1;
   justify-content: flex-end !important;
   border-bottom: 0px !important;
}

.menu-flex-end .ant-menu-item {
   border-bottom: 1px solid #f0f0f0;
}

.site-page-header {
   display: flex;
   justify-content: center;
   flex-direction: column;
   align-items: center;
   padding: 20px 0;
}

.content-wrapper {
   width: 90%;
   margin: auto;
   min-height: 680px;
}

.d-flex {
   display: flex;
}

.moderation-card-wrapper .media-card,
.approved-card-wrapper .media-card {
   height: calc(100% - 40px);
   margin: 20px !important;
}

.moderation-card-wrapper .media-card .ant-card-body {
   padding-bottom: 66px;
}

.moderation-card-wrapper .media-card .media-actions,
.approved-card-wrapper .media-card .media-actions {
   position: absolute;
   bottom: 24px;
   width: calc(100% - 48px);
}

.moderation-card-wrapper .media-card .ant-image,
.approved-card-wrapper .media-card .ant-image {
   width: 100% !important;
   height: auto !important;
}

.moderation-card-wrapper .img-wrapper,
.approved-card-wrapper .img-wrapper {
   width: 200px;
   height: 200px;
}

.moderation-card-wrapper .img-wrapper .ant-image-img,
.approved-card-wrapper .img-wrapper .ant-image-img {
   width: 200px;
   height: 200px;
}

.moderation-card-wrapper .video-container,
.approved-card-wrapper .video-container {
   width: 200px;
   height: 200px;
}

.moderation-card-wrapper .video-wrapper img,
.approved-card-wrapper .video-wrapper img {
   width: 200px;
   height: 200px;
}

.media-card-container .ant-card-body {
   padding: 24px 0px;
}

.moderation-card-wrapper .ant-card-actions,
.approved-card-wrapper .ant-card-actions {
   background: unset;
   border-top: unset;
}

.img-wrapper {
   background: #f3f3f3;
   overflow: hidden;
   width: 250px;
   height: 250px;
   margin: auto;
}

.img-wrapper .ant-image {
   display: block;
   position: inherit;
}

.img-wrapper .ant-image-img {
   width: 250px;
   height: 250px;
   object-fit: cover;
}

.video-loader {
   width: 100%;
   text-align: center;
   padding-top: 10px;
}

.video-container {
   background: #f3f3f3;
   overflow: hidden;
   width: 250px;
   height: 250px;
   margin: auto;
}

.video-wrapper img {
   width: 250px;
   height: 250px;
   object-fit: cover;
   cursor: pointer;
}

.wrapper-class {
   padding: 1rem;
   border: 1px solid #ccc;
}

.editor-class {
   background-color: lightgray;
   padding: 1rem;
   border: 1px solid #ccc;
}

.toolbar-class {
   border: 1px solid #ccc;
}

.preview {
   padding: 1rem;
   margin-top: 1rem;
}

body > iframe {
   pointer-events: none;
}

.page-footer {
   min-height: 180px;
}

.ant-image-preview-img-wrapper {
   padding-top: 65px !important;
}
